import store from "../store";
import * as Sentry from "@sentry/vue";

export default function (router) {
  // App name definition
  const appName = "ScheduleLeave";

  // Runs before each route is accessed - authentication check takes place here
  router.beforeEach((to, from, next) => {
    /* Router rules
      - If no token is found, redirect to /login
      - If token found but has expired, redirect to /login
      - All other routes need a valid token
      - Any pages not found, if a valid token is present, will redirect to error page, else will direct to login
    */
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      //do we have token?
      if (router.app.$helpers.getExpiryTimeFromStoredToken() > new Date()) {
        router.app.$HTTP
          .get("/users/me")
          .then((response) => {
            // We want to update the vuex store
            store.commit("updateUser", response.data);
            Sentry.setUser({ id: store.state.user.id });
            if (process.env.VUE_APP_PENDO_ID && process.env.VUE_APP_PENDO_ID.length) {
              router.app.$helpers.pendoInstall(process.env.VUE_APP_PENDO_ID, store.state.user.id, store.state.user.customer.id);
              if (store.state.user.extra.showPendoGuide) {
                setTimeout(function() {
                  router.app.$helpers.pendoDisplayGuide(process.env.VUE_APP_PENDO_GUIDE_ID);
                  router.app.$HTTP.post('/users/hide-pendo');
                }, 1000)
              }
            }
            if (response.data.customer.extra.hasTrialExpired) {
              const expectedPage = router.app.$helpers.isCurrentUserInGroup(
                store.state.user,
                [
                  router.app.USER_GROUPS.SUPER_ADMIN,
                  router.app.USER_GROUPS.ADMIN,
                ]
              )
                ? "billing-card"
                : "trial-expired";
              if (to.name !== expectedPage) {
                next({ name: expectedPage });
              } else {
                next();
              }
            } else if (
              to.matched.some((record) => {
                return (
                  (record.parent !== undefined &&
                    (!record.parent.meta.requiresGroups ||
                      router.app.$helpers.isCurrentUserInGroup(
                        store.state.user,
                        record.parent.meta.requiresGroups
                      ))) ||
                  (!record.parent &&
                    (!record.meta.requiresGroups ||
                      router.app.$helpers.isCurrentUserInGroup(
                        store.state.user,
                        record.meta.requiresGroups
                      )))
                );
              })
            ) {
              next();
            } else {
              next({
                name: "wallchart",
              });
            }
          })
          .catch((errorResponse) => {
            // Invalid token or expired
            if (
              ["ETNP", "ECNAC", "ETE"].includes(
                errorResponse.response?.data?.errorCode
              )
            ) {
              router.app.$helpers.authLogout();
              next({
                name: "login",
                query: { redirect: to.fullPath },
              });
            }
            // Error happened, they don't have access, so direct them to verify based on error code
            else if (errorResponse.response?.data?.errorCode === "ECNV") {
              if (to.name !== "verify") {
                next({
                  name: "verify",
                });
              } else {
                // This will send them to verify page
                next();
              }
            } else {
              // // Else, send them to error page (route unknown)
              next({
                path: "*",
                name: "error",
                params: { msg: "Error", type: 404 },
              });
            }
          });
      } else {
        const query = {};
        if (to.fullPath !== "") {
          query.redirect = to.fullPath;
        }
        next({
          path: "/login",
          query: query,
        });
      }
    } else {
      next();
    }

    // Sets the page title to default plus extra title if one exists if not it's blank
    document.title = appName + (" - " + to.meta.title || "");
  });
}
