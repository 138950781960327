export default {
    apiUrl: "https://api.scheduleleave.com",
    stripe: "pk_live_dgjm9E6MOZS8Bdgp4HZshwin",
    slackAuthUrl: "https://slack.com/oauth/v2/authorize?client_id=510733482960.512524313143&scope=incoming-webhook&redirect_uri=https://app.scheduleleave.com/admin/integrations",
    debug: false,
    companyName: "Mussko LTD",
    environment: "production",
    debounceTimeout: 500,
    timedOutWindowDelayMin: 10,
    refreshTokenMin: 30
};

