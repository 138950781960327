<template>
  <div>
    <b-alert
      variant="danger"
      id="info"
      class="mb-4 text-center"
      v-if="errors"
      show
      fade
    >
      <p
        class="my-0"
        :key="index"
        v-for="(error, index) in parsedErrors"
        v-html="error"
      />
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "FormErrors",
  props: ["errors", "errorsOverwrites"],
  data() {
    return {};
  },
  computed: {
    parsedErrors() {
      return this.convertValidationErrors(this.errors);
    },
  },
  methods: {
    convertValidationErrors(errorResponse) {
      const root = this;
      try {
        if (typeof errorResponse === "string") {
          return [errorResponse];
        }
        if (Array.isArray(errorResponse)) {
          return errorResponse;
        }
        if (!errorResponse.response || errorResponse.response.status !== 400) {
          throw "We couldn't process request at this time. Please try again";
        }
        let errors = [];
        if (errorResponse.response?.data?.errorCode === 6) {
          errors = errorResponse.response.data.errorDetails;
        } else {
          Object.keys(errorResponse.response.data).forEach((item) => {
            Object.keys(errorResponse.response.data[item]).forEach(
              (message) => {
                if (
                  root.errorsOverwrites &&
                  Object.prototype.hasOwnProperty.call(
                    root.errorsOverwrites,
                    message
                  )
                ) {
                  errors.push(root.errorsOverwrites[message]);
                } else {
                  errors.push(errorResponse.response.data[item][message]);
                }
              }
            );
          });
        }

        return errors;
      } catch (e) {
        return [e];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/variables.scss";
#info {
  max-height: 500px;
  overflow-y: auto;
}
</style>
