<template>
  <div>
    <TimeoutWindow />
    <b-modal
      ref="logoutModal"
      centered
      size="md"
      header-bg-variant="primary"
      header-text-variant="light"
      hide-footer
      title="Logout"
    >
      <b-row>
        <b-col cols="12">
          <p class="text-center mt-4 mb-5">Are you sure you want to logout?</p>
          <b-button @click="logout()" class="float-right" variant="primary"
            >Yes</b-button
          >
          <b-button
            @click="$refs.logoutModal.hide()"
            variant="outline-secondary"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <b-row no-gutters>
      <b-col>
        <b-navbar role="Navigation" toggleable="lg" type="dark">
          <b-navbar-brand class="mr-2 mr-lg-5" :to="{ name: 'wallchart' }">
            ScheduleLeave
          </b-navbar-brand>
          <b-navbar-toggle target="nav_collapse" />
          <b-collapse is-nav id="nav_collapse">
            <b-navbar-nav v-if="!trialExpired">
              <b-nav-item
                :to="{ name: 'wallchart' }"
                :class="enableAdditionalMargins ? 'mx-2' : ''"
                >Wallchart</b-nav-item
              >
              <b-nav-item
                :to="{ name: 'calendar' }"
                :class="enableAdditionalMargins ? 'mx-2' : ''"
                >Calendar</b-nav-item
              >
              <template
                v-if="
                  $helpers.isCurrentUserInGroup($store.state.user, [
                    USER_GROUPS.SUPER_ADMIN,
                    USER_GROUPS.ADMIN,
                    USER_GROUPS.APPROVER,
                  ])
                "
              >
                <b-nav-item
                  :to="{ name: 'approve' }"
                  :class="[
                    'text-nowrap',
                    enableAdditionalMargins ? 'mx-2' : '',
                  ]"
                  >Approve
                  <b-badge
                    variant="warning"
                    v-if="$store.state.pendingLeaves"
                    >{{ $store.state.pendingLeaves }}</b-badge
                  >
                </b-nav-item>
              </template>
              <template
                v-if="
                  $helpers.isCurrentUserInGroup($store.state.user, [
                    USER_GROUPS.SUPER_ADMIN,
                    USER_GROUPS.ADMIN,
                    USER_GROUPS.APPROVER,
                  ])
                "
              >
                <b-nav-item-dropdown
                  text="Reports"
                  :class="[
                    dropdownReportsActive,
                    enableAdditionalMargins ? 'mx-2' : '',
                  ]"
                >
                  <b-dropdown-item
                    :to="{ name: 'report-activity' }"
                    class="py-1 px-3"
                    ><font-awesome-icon
                      icon="chart-area"
                      fixed-width
                      class="mr-2"
                    />
                    Activity</b-dropdown-item
                  >
                  <b-dropdown-item
                    :to="{ name: 'report-user-leave-stats' }"
                    class="py-1 px-3"
                    ><font-awesome-icon icon="list" fixed-width class="mr-2" />
                    User Leave Stats</b-dropdown-item
                  >
                  <b-dropdown-item
                    :to="{ name: 'report-user-summary' }"
                    class="py-1 px-3"
                    ><font-awesome-icon
                      :icon="['far', 'address-card']"
                      fixed-width
                      class="mr-2"
                    />
                    User Summary</b-dropdown-item
                  >
                  <b-dropdown-item
                    :to="{ name: 'report-booking-log' }"
                    class="py-1 px-3"
                    ><font-awesome-icon
                      :icon="['far', 'calendar-check']"
                      fixed-width
                      class="mr-2"
                    />
                    Booking Log</b-dropdown-item
                  >
                  <template
                    v-if="$helpers.showBradfordFactor($store.state.user)"
                  >
                    <b-dropdown-item
                      :to="{ name: 'report-bradford-factor' }"
                      class="py-1 px-3"
                      ><font-awesome-icon
                        icon="star-half-alt"
                        fixed-width
                        class="mr-2"
                      />
                      Bradford Factor</b-dropdown-item
                    >
                  </template>
                </b-nav-item-dropdown>
              </template>
              <template
                v-if="
                  $helpers.isCurrentUserInGroup($store.state.user, [
                    USER_GROUPS.SUPER_ADMIN,
                    USER_GROUPS.ADMIN,
                  ])
                "
              >
                <b-nav-item-dropdown
                  text="Admin"
                  :class="[
                    dropdownAdminActive,
                    enableAdditionalMargins ? 'mx-2' : '',
                  ]"
                >
                  <b-dropdown-text class="font-weight-normal"
                    >Company</b-dropdown-text
                  >
                  <b-dropdown-item :to="{name: 'admin-settings'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="cogs"
                      fixed-width
                      class="mr-2"
                    />Settings
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'admin-billing'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="money-check"
                      fixed-width
                      class="mr-2"
                    />Billing
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-text class="font-weight-normal"
                    >User</b-dropdown-text
                  >
                  <b-dropdown-item :to="{name: 'admin-departments'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="user-tie"
                      fixed-width
                      class="mr-2"
                    />Departments
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'admin-offices'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="building"
                      fixed-width
                      class="mr-2"
                    />Offices
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'admin-roles'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="user-md"
                      fixed-width
                      class="mr-2"
                    />Roles
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'admin-users'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="user"
                      fixed-width
                      class="mr-2"
                    />Users
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-text class="font-weight-normal"
                    >Other</b-dropdown-text
                  >
                  <b-dropdown-item :to="{name: 'admin-leave-types'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="plane"
                      fixed-width
                      class="mr-2"
                    />Leave Types
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'admin-public-holidays'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="umbrella-beach"
                      fixed-width
                      class="mr-2"
                    />Public Holidays
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'admin-locked-dates'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="lock"
                      fixed-width
                      class="mr-2"
                    />Locked Dates
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'admin-integrations'}" class="py-1 px-3">
                    <font-awesome-icon
                      :icon="['fab', 'slack']"
                      fixed-width
                      class="mr-2"
                    />Integrations
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'admin-export'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="file-download"
                      fixed-width
                      class="mr-2"
                    />Export Data
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name: 'admin-import'}" class="py-1 px-3">
                    <font-awesome-icon
                      icon="file-upload"
                      fixed-width
                      class="mr-2"
                    />Import Data
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </template>
              <template
                v-if="
                  $helpers.isCurrentUserInGroup($store.state.user, [
                    USER_GROUPS.SUPER_ADMIN,
                  ])
                "
              >
                <b-nav-item-dropdown
                  text="SuperAdmin"
                  :class="[
                    dropdownSuperAdminActive,
                    enableAdditionalMargins ? 'mx-2' : '',
                  ]"
                >
                  <b-dropdown-item
                    :to="{ name: 'super-admin-customers' }"
                    class="py-1 px-3"
                    >Customers</b-dropdown-item
                  >
                  <b-dropdown-item
                    :to="{ name: 'super-admin-invoices' }"
                    class="py-1 px-3"
                    >Invoices</b-dropdown-item
                  >
                  <b-dropdown-item
                    :to="{ name: 'super-admin-reporting' }"
                    class="py-1 px-3"
                    >Reporting</b-dropdown-item
                  >
                  <b-dropdown-item
                      :to="{ name: 'super-admin-report-customer-referrer' }"
                      class="py-1 px-3"
                  >Customer Referrer Report</b-dropdown-item
                  >
                  <b-dropdown-item
                    :to="{ name: 'super-admin-email-log' }"
                    class="py-1 px-3"
                    >Email Log</b-dropdown-item
                  >
                  <b-dropdown-item
                    :to="{ name: 'super-admin-public-holidays' }"
                    class="py-1 px-3"
                    >Public Holidays</b-dropdown-item
                  >
                </b-nav-item-dropdown>
              </template>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item
                v-if="showTrialLink"
                :to="{ name: 'admin-billing-card' }"
                class="trial-expiry-link mt-2 mr-2 font-weight-bold"
              >
                Trial Expires In {{ trialLeftInDays }} Day(s)
                <font-awesome-icon icon="arrow-alt-circle-right"/>
              </b-nav-item>
              <b-nav-item v-if="haveRunningTasks">
                <b-spinner class="mt-2" v-b-tooltip.left title="Tasks in progress"></b-spinner>
              </b-nav-item>
              <b-nav-form
                v-if="!trialExpired"
                class="mb-2 mb-lg-0 user-search-form mt-3 mt-sm-1"
                @submit.prevent="userSearchExecute"
              >
                <b-form-input
                  class="my-0 round-borders"
                  placeholder="Search"
                  @input="userSearchExecute"
                  @blur="userSearchCancel"
                  v-model="userSearchQuery"
                  autocomplete="off"
                />
                <div v-show="userSearchPopup" class="results">
                  <b-list-group>
                    <template v-if="userSearchLoading">
                      <b-list-group-item class="d-flex justify-content-center">
                        <b-spinner small class="text-center" />
                      </b-list-group-item>
                    </template>
                    <template v-else-if="userSearchItems.length">
                      <b-list-group-item
                        v-for="user in userSearchItems"
                        :key="user.id"
                      >
                        <template v-if="canDisplayLinkToCalendar(user)"
                          ><b-link
                            :to="{
                              path:
                                '/calendar' +
                                ($store.state.user.id !== user.id
                                  ? '/' + user.id
                                  : ''),
                            }"
                          >
                            <Avatars
                              size="small"
                              randomColour
                              :src="user.avatarUrl"
                              :name="user.firstName"
                            />
                            <span class="ml-2"
                              >{{ user.firstName }}
                              {{ user.lastName.charAt(0) }}.</span
                            >
                          </b-link>
                        </template>
                        <template v-else>
                          <div
                            v-b-tooltip
                            title="Calendar Privacy blocks access to this user calendar"
                          >
                            <Avatars
                              size="small"
                              randomColour
                              :src="user.avatarUrl"
                              :name="user.firstName"
                            />
                            <span class="ml-2"
                              >{{ user.firstName }}
                              {{ user.lastName.charAt(0) }}.</span
                            >
                          </div>
                        </template>
                      </b-list-group-item>
                    </template>
                    <template v-else>
                      <b-list-group-item> No users found </b-list-group-item>
                    </template>
                  </b-list-group>
                </div>
              </b-nav-form>
              <b-nav-item-dropdown data-cy="userDropdown" right>
                <!-- Using button-content slot -->
                <template v-slot:button-content>
                  <Avatars
                    aria-label="User Dropdown Menu"
                    :noLazy="true"
                    :src="$store.state.user.avatarUrl"
                    :name="$store.state.user.firstName"
                    style="display: inline-block; vertical-align: top"
                  />
                </template>
                <template v-if="!trialExpired">
                  <b-dropdown-item :to="{ name: 'account' }" class="py-1 px-3">
                    <font-awesome-icon
                      icon="user-circle"
                      fixed-width
                      class="mr-2"
                    />Account
                  </b-dropdown-item>
                  <b-dropdown-item :to="{ name: 'feeds' }" class="py-1 px-3">
                    <font-awesome-icon
                      icon="rss"
                      fixed-width
                      class="mr-2"
                    />Calendar Feeds
                  </b-dropdown-item>
                </template>
                <b-dropdown-item
                  class="py-1 px-3"
                  target="_blank"
                  href="https://scheduleleave.helpscoutdocs.com/"
                >
                  <font-awesome-icon
                    icon="question-circle"
                    fixed-width
                    class="mr-2"
                  />Help Guides
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item
                  class="py-1 px-3"
                  data-cy="logoutLink"
                  @click.prevent="$refs.logoutModal.show()"
                >
                  <font-awesome-icon
                    icon="sign-out-alt"
                    fixed-width
                    class="mr-2"
                  />Logout
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TimeoutWindow from "../../components/TimeoutWindow";
import { mapGetters } from 'vuex';

// Main
export default {
  name: "AppHeader",
  components: {
    TimeoutWindow,
  },
  data() {
    return {
      userSearchQuery: null,
      userSearchItems: [],
      userSearchPopup: false,
      userSearchLoading: false,
      countPendingLeavesInterval: null,
      trialExpired: this.$store.state.user?.customer?.extra?.hasTrialExpired,
    };
  },
  mounted() {
    const root = this;
    if (
      !root.trialExpired &&
      root.$helpers.isCurrentUserInGroup(root.$store.state.user, [
        root.USER_GROUPS.SUPER_ADMIN,
        root.USER_GROUPS.ADMIN,
        root.USER_GROUPS.APPROVER,
      ])
    ) {
      root.countPendingLeaves();
      root.countPendingLeavesInterval = setInterval(() => {
        root.countPendingLeaves();
      }, 300000);
    }
  },
  beforeDestroy() {
    clearInterval(this.countPendingLeavesInterval);
    this.countPendingLeavesInterval = null;
  },
  methods: {
    canDisplayLinkToCalendar(user) {
      return (
        !this.$store.state.user.customer?.settings?.privacyHideUserCalendars ||
        this.$store.state.user.id === user.id ||
        this.$helpers.isApproverFor(this.$store.state.user, user)
      );
    },
    countPendingLeaves() {
      const root = this;
      root.$HTTP
        .get("/leaves/pending/count")
        .then((response) => {
          root.$store.commit("updatePendingLeaves", response.data.total);
        })
        .catch(() => {});
    },
    userSearchCancel() {
      const root = this;
      setTimeout(() => {
        root.userSearchPopup = false;
        root.userSearchItems = [];
      }, 500);
    },
    userSearchExecute() {
      const root = this,
        searchParameters = {
          search: {
            name: root.userSearchQuery.trim(),
            status: "ACTIVE",
          },
          with: ["userTerms"],
          orderBy: "name,ASC",
          limit: 10,
        };
      if (searchParameters.search.name.length < 2) {
        root.userSearchPopup = false;
        return;
      }
      root.userSearchLoading = true;
      root.userSearchPopup = true;
      root.debouncing(() => {
        root.$HTTP
          .get("/users", {
            params: searchParameters,
          })
          .then((response) => {
            root.userSearchItems = response.data.resources;
            root.userSearchLoading = false;
          })
          .catch(() => {
            root.userSearchItems = [];
            root.userSearchLoading = false;
          });
      });
    },
  },
  computed: {
    ...mapGetters(['haveRunningTasks']),
    dropdownReportsActive() {
      return this.$route.path.startsWith("/reports") ? "active" : "";
    },
    dropdownAdminActive() {
      return this.$route.path.startsWith("/admin") ? "active" : "";
    },
    dropdownSuperAdminActive() {
      return this.$route.path.startsWith("/superadmin") ? "active" : "";
    },
    enableAdditionalMargins() {
      return window.innerWidth > 1100;
    },
    showTrialLink() {
      return this.$store.state.user.customer?.extra?.paymentDetailsReceived !== true
          && this.$helpers.isCurrentUserInGroup(this.$store.state.user, ['admin',])
          && this.$store.state.user.customer?.extra?.trialExpiryDate
          && this.$moment(this.$store.state.user.customer?.extra?.trialExpiryDate) > this.$moment();
    },
    trialLeftInDays() {
      return this.$moment(this.$store.state.user.customer?.extra?.trialExpiryDate).diff(this.$moment(), 'days');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
@import "../../assets/css/variables.scss";

.navbar {
  border-bottom: 1px solid $color-purple;
  background: $color-blue;
  box-shadow: $box-shadow;
  color: $color-white;

  .navbar-toggler {
    border-color: $color-white;
  }

  .b-dropdown-text {
    color: $color-light-purple;
  }

  .navbar-brand {
    color: $color-white;
    border: none;
    font-size: $font-size-medium;
    font-weight: bold;
  }

  .navbar-nav {

    .trial-expiry-link {
      a.nav-link {
        color: $color-yellow-light;

        &.router-link-active {
          border-bottom: 0;
        }
      }
    }

    .nav-link {
      color: $color-white;
      &:hover {
        color: $color-lightest-grey;
      }
      &.router-link-active {
        border-bottom: 1px solid $color-white;
      }
    }

    .nav-item.active {
      a {
        border-bottom: 1px solid $color-white;
      }
    }
    .dropdown-menu {
      border: 1px solid $color-lightest-grey;
      box-shadow: $box-shadow-darker;
      li {
        a {
          color: $color-purple;

          &:active {
            color: $color-blue;
          }
        }
        &:hover:not(.font-weight-normal) {
          a {
            color: $color-blue;
            background: $color-white;
          }
          border-radius: 5px;
          transform: scale(1.08);
          transition: all 0.1s ease-in;
          box-shadow: $box-shadow;
          background: $color-white;
        }
      }
    }

    .user-search-form {
      position: relative;
      .round-borders {
        border-radius: 20px;
      }
      .results {
        color: $color-grey;
        position: absolute;
        top: 4em;
        border-radius: 15px;
        width: 100%;
        z-index: 100;
        img,
        .blankAvatar {
          float: left;
        }
        a {
          color: $color-blue;
          display: block;
        }
        span {
          display: inline-block;
          margin-top: 0.3em;
        }
      }
    }
  }
}
</style>
