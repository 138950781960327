import {HTTP} from "../helpers/axios";

const RUNNING_TASK_MAX_TRY = 5;

export default {
  addRunningTask({commit, state}, payload) {
    const downloadCheck = setInterval(() => {
      const currentTaskIndex = state.runningTasks.findIndex(item => item.taskId === payload.taskId);
      if (currentTaskIndex === -1) {
        return;
      }
      if (state.runningTasks[currentTaskIndex].counter > (payload.maxTry ?? RUNNING_TASK_MAX_TRY)) {
        commit('removeRunningTask', payload.taskId);
        if (payload.callbackFailure) {
          payload.callbackFailure();
        }
        return;
      }
      HTTP
        .get(`/tasks/${payload.taskId}`)
        .then((successResponse) => {
          if (["EXECUTED","FAILED"].includes(successResponse.data.status)) {
            commit('removeRunningTask', payload.taskId);
            if (successResponse.data.status === "EXECUTED" && payload.callbackSuccess) {
              payload.callbackSuccess(successResponse.data);
            }
            else if (successResponse.data.status === "FAILED" && payload.callbackFailure) {
              payload.callbackFailure(successResponse.data);
            }
          } else {
            commit('increaseCounterRunningTask', payload.taskId);
          }
        })
        .catch((errorResponse) => {
          commit('removeRunningTask', payload.taskId);
          if (payload.callbackFailure) {
            payload.callbackFailure(errorResponse.data);
          }
        });
    }, 4000);

    commit('createRunningTask', {
      taskId: payload.taskId,
      interval: downloadCheck,
    });
  },
};
