<template>
  <div class="d-inline-block">
    <b-modal
      no-close-on-backdrop
      centered
      ref="confirmationModal"
      :header-bg-variant="headerClass"
      header-text-variant="light"
      hide-footer
      :title="title"
    >
      <FormErrors :errors="errors" />
      <p class="my-4 text-center">
        {{ question }}
      </p>
      <hr />
      <b-btn variant="outline-secondary" @click="hideModal">Cancel</b-btn>
      <b-btn
        :variant="buttonClass"
        class="float-right"
        @click="operationConfirmed"
        >Confirm</b-btn
      >
    </b-modal>
    <template>
      <span v-b-tooltip :title="tooltipTitle">
        <font-awesome-icon
          :icon="icon"
          :class="`cursor-pointer ${iconClass}`"
          @click="openModal"
        />
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: "confirmationModal",
  props: {
    name: {
      type: [String],
      default: null,
    },
    item: Object,
    url: String,
    type: {
      type: [String],
      required: true,
      validator(value) {
        return ["DELETE", "STATUS_CHANGE", "CUSTOM"].indexOf(value) !== -1;
      },
    },
    customTooltip: {
      type: [String],
    },
    customWord: {
      type: [String],
    },
    customIcon: {
      type: [String, Array],
      default: "list",
    },
    customIconClass: {
      type: String,
      default: "text-primary",
    },
    customQuestion: {
      type: String,
      default: "Do you want to proceed?",
    },
    customTitle: {
      type: [String],
    },
    customResponseSuccess: {
      type: [String],
    },
    customResponseError: {
      type: [String],
    },
    customUrlMethod: {
      type: [String],
      default: 'get',
    },
    refreshTableName: {
      type: [String],
      required: false,
    },
  },
  data() {
    return {
      errors: null,
      question: 'Do you want to proceed?',
      title: null,
      headerClass: null,
      buttonClass: null,
      iconClass: "",
      icon: ["fa", "power-off"],
      tooltipTitle: "",
      localItem: this.item,
    };
  },
  mounted() {
    const root = this;
    switch (root.type) {
      case "CUSTOM":
        root.icon = root.customIcon;
        root.iconClass = root.customIconClass;
        root.tooltipTitle = root.customTooltip;
        root.question = root.customQuestion;
        root.title = root.customTitle;
        root.headerClass = "primary";
        root.buttonClass = "primary";
        break;
      case "DELETE":
        root.icon = ["far", "trash-alt"];
        root.iconClass = "text-danger";
        root.tooltipTitle = `Delete "${root.name ? root.name : root.item.name}"`;
        root.question = `Are you sure that you want to delete "${root.name ? root.name : root.item.name}"?`
        root.title = "Deletion";
        root.headerClass = "danger";
        root.buttonClass = "danger";
        break;
      case "STATUS_CHANGE":
        root.icon = ["fa", "power-off"];
        root.iconClass = root.item.status === "ACTIVE" ? "text-danger" : "text-success";
        root.tooltipTitle = `${root.item.status === "ACTIVE" ? "Deactivate" : "Activate"} "${root.name ? root.name : root.item.name}"`;
        root.question = `Are you sure you want to ${root.item.status === "ACTIVE" ? "deactivate" : "activate"} "${root.name ? root.name : root.item.name}"?`
        if (root.item.status === "ACTIVE") {
          root.title = "Deactivation";
          root.headerClass = "danger";
          root.buttonClass = "danger";
        } else {
          root.title = "Activation";
          root.headerClass = "success";
          root.buttonClass = "success";
        }
        break;
      default:
    }
  },
  methods: {
    openModal() {
      this.errors = null;
      this.$refs.confirmationModal.show();
    },
    updateParent() {
      if (this.refreshTableName) {
        this.$root.$emit("bv::refresh::table", this.refreshTableName);
      }
    },
    hideModal() {
      this.$refs.confirmationModal.hide();
    },
    operationConfirmed() {
      const root = this;
      switch (root.type) {
        case "CUSTOM":
          root.$HTTP.request({
            method: root.customUrlMethod,
            url: root.url
          }).then(() => {
            root.$snotify.success(root.customResponseSuccess);
            root.hideModal();
            root.updateParent();
          }).catch(() => {
            root.errors = root.customResponseError;
          });
          break;
        case "DELETE":
          root.$HTTP
            .delete(this.url)
            .then(() => {
              root.$snotify.success(`"${root.name ? root.name : root.item.name}" was deleted`);
              root.hideModal();
              root.updateParent();
            })
            .catch(() => {
              root.errors = `"${root.name ? root.name : root.item.name}" was not deleted`;
            });
          break;
        case "STATUS_CHANGE":
          root.$HTTP
            .patch(this.url, {
              status: root.item.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
            })
            .then(() => {
              const newStatus = root.item.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
              root.localItem.status = newStatus;
              root.$snotify.success(`Status for "${root.name ? root.name : root.item.name}" Was Changed`);
              root.iconClass = newStatus === "ACTIVE" ? "text-danger" : "text-success";
              root.tooltipTitle = `${newStatus === "ACTIVE" ? "Deactivate" : "Activate"} ${root.name ? root.name : root.item.name}`;
              root.$emit("update:status", root.localItem);
              root.hideModal();
            })
            .catch(() => {
              root.errors = "Status Was Not Changed. Please try again.";
            });
          break;
      }
    },
  },
};
</script>
