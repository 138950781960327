// Generic error handler to re-route to the erro route
export default {
  methods: {
    error(error) {
      if (
        typeof error.response !== "undefined" &&
        typeof error.response.status !== "undefined"
      ) {
        this.$router.push({
          name: "error",
          params: {
            type: error.response.status,
            msg: "An error has occurred, please try again",
          },
        });
      }
    },
  },
};
