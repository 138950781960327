<template>
  <div class="whiteBox">
    <!-- Table view -->
    <div aria-label="loading" class="item" v-if="viewType === 'table'">
      <div class="col-12">
        <div class="row" :key="key" v-for="key in 5">
          <div class="col-2 big" :key="key" v-for="key in 5"></div>
        </div>
      </div>
    </div>
    <!-- -->
    <div
      v-else-if="viewType === 'spinner'"
      class="d-flex justify-content-center py-5"
    >
      <b-spinner aria-label="loading" class="my-5" />
    </div>

    <!-- Users list view -->
    <div v-else>
      <div aria-label="loading" class="item" :key="key" v-for="key in 2">
        <div class="col-1">
          <div class="avatar"></div>
        </div>
        <div>
          <div class="row">
            <div class="col-12 big" :key="key" v-for="key in 5"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    viewType: {
      type: String,
      default: "table",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/variables.scss";

$color: #ced4da !default;
$gutter: 30px !default;
$spacer: 15px !default;
$avatar-border-radius: 50% !default;

.item {
  &,
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  clear: both;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: $gutter ($gutter / 2) ($gutter - $spacer) ($gutter / 2);
  overflow: hidden;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation 0.8s linear infinite;
    background: linear-gradient(
        to right,
        rgba($color-white, 0) 46%,
        rgba($color-white, 0.35) 50%,
        rgba($color-white, 0) 54%
      )
      50% 50%;
  }

  > * {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding-right: ($gutter / 2);
    padding-left: ($gutter / 2);
  }
}

.row {
  div {
    height: 10px;
    margin: 0 auto ($spacer / 2) auto;
    background-color: $color;
  }

  .big,
  &.big div {
    height: 20px;
    margin-bottom: $spacer;
  }

  .empty {
    background-color: rgba($color-white, 0);
  }
}

.avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: $color;
  margin-bottom: $spacer;
  border-radius: $avatar-border-radius;
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
}

.picture {
  width: 100%;
  height: 120px;
  background-color: $color;
  margin-bottom: $spacer;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}

@media only screen and (max-width: 1200px) {
  .col-1 {
    display: none;
  }
}
</style>
