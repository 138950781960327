<template>
  <div>
    <template v-if="src && src !== ''">
      <!-- Image if present -->
      <b-img-lazy
        :aria-label="name"
        rounded
        blank-color="#bbb"
        v-if="!noLazy"
        :src="src"
        :class="`img ${size}`"
      />

      <!-- Image if present, we don't always want to lazy load as it can cause issues -->
      <b-img
        :aria-label="name"
        rounded
        blank-color="#bbb"
        v-else
        :src="src"
        :class="`img ${size}`"
        :alt="name"
      />
    </template>

    <!-- Square image for approve page -->
    <template v-else>
      <template v-if="imgType === 'square'">
        <div :aria-label="name" :class="color">
          <div class="square">
            <div class="cell">{{ name ? name.charAt(0) : "?" }}</div>
          </div>
        </div>
      </template>
      <!-- Circle with initial if no image is present -->
      <div v-else :class="color">
        <div :aria-label="name" :class="`letter ${size}`">
          {{ name ? name.charAt(0) : "?" }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    noLazy: {
      type: Boolean,
      default: false,
    },
    src: String,
    size: {
      type: String,
      default: "small",
    },
    imgType: {
      type: String,
      default: "circle",
    },
    name: String,
    randomColour: Boolean,
  },
  computed: {
    // Randomise colour
    color: (root) => {
      if (root.randomColour) {
        return `${root.imgType} blankAvatar ${root.size} color${Math.floor(
          Math.random() * 8
        )}`;
      }
      // Else use image
      else {
        return `${root.imgType} blankAvatar color1`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/variables.scss";
:not(.letter).maxHeight {
  height: 100%;
  width: 100%;
}

.img {
  object-fit: cover;
  background-color: $color-lightest-grey;
}

.square {
  color: white;
  height: 230px;
  border-radius: 5px;
  display: table;
  width: 100%;
  font-size: 2em;
}

.cell {
  width: 100%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

img.rounded:not(.maxHeight),
.circle.blankAvatar {
  border-radius: 50% !important;
  border: 1px solid $color-light-grey;
}

.circle.blankAvatar {
  text-align: center;
  color: $color-white;
  font-weight: bold;
  display: inline-block;
}

.letter {
  display: table-cell;
  vertical-align: middle;
  font-style: normal;
}

.small {
  height: 35px;
  width: 35px;
  font-size: 1.2rem;
}

.medium {
  height: 50px;
  width: 50px;
}

.large {
  height: 100px;
  min-width: 100px;
}

.x-large {
  height: 150px;
  width: 150px;
  font-size: 3em;
}

.full {
  height: 100%;
  width: 100%;
}

.color0 {
  background: $color-blue;
}

.color1 {
  background: $color-purple;
}

.color2 {
  background: $color-green;
}

.color3 {
  background: $color-black;
}

.color4 {
  background: $color-grey;
}

.color5 {
  background: $color-yellow;
}

.color6 {
  background: $color-purple-dark;
}

.color7 {
  background: $color-turquoise;
}

.color8 {
  background: $color-teal;
}
</style>
