// Importing libs, conf etc.
import Vue from "vue";
import App from "./App";
import router from "./router/router";
import store from "./store";
import error from "./mixins/error";
import BootstrapVue from "bootstrap-vue";
import Snotify, { SnotifyPosition } from "vue-snotify";
import * as Sentry from "@sentry/vue";
import appConfig from "./config";
import CONST from "./mixins/const.js";
import debouncing from "./mixins/debouncing.js";
import common from "./mixins/common.js";
import { helpers } from "./helpers/helpers.js";
import { HTTP } from "./helpers/axios";
// eslint-disable-next-line no-unused-vars
import * as fontAwesome from "./imports/fontAwesome";
import AppHeader from "./views/common/AppHeader";
import Loading from "./components/Loading";
import Avatars from "./components/Avatars";
import OpenGuide from "./components/OpenGuide";
import InlineEdit from "./components/InlineEdit";
import FormErrors from "./components/FormErrors";
import moment from "moment-timezone";
import ConfirmationModal from "./components/ConfirmationModal";
import LoadScript from "vue-plugin-load-script";

// We don't want sentry on local dev
if (!appConfig.debug) {
  Sentry.init({
    Vue: Vue,
    dsn:
      "https://9c6eb2d0ef2b45549a0659e0e365af27@o235343.ingest.sentry.io/1400727",
    environment: appConfig.environment,
    release: JSON.stringify(require("../package.json").version),
  });
}

// Telling vue to use components for bootstrap, snotify and vue moment
Vue.use(BootstrapVue);
Vue.use(Snotify, {
  toast: {
    timeout: 1000,
    showProgressBar: false,
    position: SnotifyPosition.rightTop,
  },
});
moment.tz.setDefault("UTC");
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(LoadScript);

// Global components
Vue.component("AppHeader", AppHeader);
Vue.component("Loading", Loading);
Vue.component("Avatars", Avatars);
Vue.component("OpenGuide", OpenGuide);
Vue.component("ConfirmationModal", ConfirmationModal);
Vue.component("InlineEdit", InlineEdit);
Vue.component("FormErrors", FormErrors);

// Global mixins
Vue.mixin(CONST);
Vue.mixin(error);
Vue.mixin(debouncing);
Vue.mixin(common);

// Global plugins - axios, helpers
Vue.use({
  install(Vue) {
    Vue.prototype.$HTTP = HTTP;
    Vue.prototype.$helpers = helpers;
  },
});

// Production tip
Vue.config.productionTip = true;

// Setting up app instance
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
