import constants from "../mixins/const.js";

export default [
  {
    // Global path redirect
    path: "/",
    redirect: { name: "wallchart" },
  },
  {
    // Login
    path: "/login/:customerId?",
    name: "login",
    props: true,
    meta: {
      title: "Login",
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'login' */ "../views/user/Login.vue"),
  },
  //SSO
  {
    path: "/sso/acs/:customerId/:token/:expiry",
    name: "sso-acs",
    props: true,
    meta: {
      title: "SSO ACS",
      requiresAuth: false,
    },
    component: () =>
        import(/* webpackChunkName: 'sso-acs' */ "../views/user/sso/Acs.vue"),
  },
  {
    // Register
    path: "/register",
    name: "register",
    meta: { title: "Register", requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: 'register' */ "../views/user/Register.vue"),
  },
  {
    // Forgot pass / change pass
    path: "/forgot/:code?/:type?",
    name: "forgot",
    props: true,
    meta: { title: "Forgot Password", requiresAuth: false },
    component: () =>
      import(
        /* webpackChunkName: 'forgot-password' */ "../views/user/ForgotPassword.vue"
      ),
  },
  {
    // Verification required
    path: "/verify/:defaultCode?",
    name: "verify",
    props: true,
    meta: { title: "Verify Account", requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: 'verify' */ "../views/user/Verify.vue"),
  },
  {
    //payment
    path: "/authorise-payment/:id/:code",
    name: "authorise-payment",
    props: true,
    meta: {
      title: "Authorise Payment",
      requiresAuth: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'payment' */ "../views/user/AuthorisePayment.vue"
      ),
  },
  {
    // Account
    path: "/account",
    name: "account",
    meta: {
      title: "Account",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: 'account' */ "../views/user/Account.vue"),
  },
  {
    // Wallchart
    path: "/wallchart",
    name: "wallchart",
    meta: {
      title: "Wallchart",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: 'wallchart' */ "../views/user/Wallchart.vue"),
  },
  {
    // Calendar
    path: "/calendar/:userId?",
    name: "calendar",
    meta: {
      title: "Calendar",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: 'calendar' */ "../views/user/Calendar.vue"),
  },
  {
    // Feeds
    path: "/feeds",
    name: "feeds",
    meta: {
      title: "Calendar Feeds",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: 'feeds' */ "../views/user/Feeds.vue"),
  },
  {
    // Trial Expired
    path: "/trial-expired",
    name: "trial-expired",
    meta: {
      title: "Trial Expired",
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'trial-expired' */ "../views/user/TrialExpired.vue"
      ),
  },
  {
    // Approval
    path: "/approve",
    name: "approve",
    meta: {
      title: "Approve",
      requiresAuth: true,
      requiresGroups: [
        constants.data().USER_GROUPS.APPROVER,
        constants.data().USER_GROUPS.ADMIN,
        constants.data().USER_GROUPS.SUPER_ADMIN,
      ],
    },
    component: () =>
      import(/* webpackChunkName: 'approve' */ "../views/user/Approve.vue"),
  },
  {
    // reports
    path: "/reports",
    meta: {
      title: "Reports",
      requiresAuth: true,
      requiresGroups: [
        constants.data().USER_GROUPS.APPROVER,
        constants.data().USER_GROUPS.ADMIN,
        constants.data().USER_GROUPS.SUPER_ADMIN,
      ],
    },
    redirect: { name: "report-activity" },
    component: () =>
      import(/* webpackChunkName: 'reports' */ "../views/reports/_Reports.vue"),
    children: [
      {
        meta: {
          title: "Activity Report",
        },
        path: "activity",
        name: "report-activity",
        component: () =>
          import(
            /* webpackChunkName: 'reports-activity' */ "../views/reports/Activity.vue"
          ),
      },
      {
        meta: {
          title: "User Leave Stats Report",
        },
        path: "user-leave-stats",
        name: "report-user-leave-stats",
        component: () =>
          import(
            /* webpackChunkName: 'reports-user-leave-stats' */ "../views/reports/UserLeaveStats.vue"
          ),
      },
      {
        meta: {
          title: "User Summary Report",
        },
        path: "user-summary",
        name: "report-user-summary",
        component: () =>
          import(
            /* webpackChunkName: 'reports-user-summary' */ "../views/reports/UserSummary.vue"
          ),
      },
      {
        meta: {
          title: "Booking Log Report",
        },
        path: "booking-log",
        name: "report-booking-log",
        component: () =>
          import(
            /* webpackChunkName: 'reports-booking-log' */ "../views/reports/BookingLog.vue"
          ),
      },
      {
        meta: {
          title: "Bradford Factor Report",
        },
        path: "bradford-factor",
        name: "report-bradford-factor",
        component: () =>
          import(
            /* webpackChunkName: 'reports-bradford' */ "../views/reports/Bradford.vue"
          ),
      },
    ],
  },
  {
    //admin
    path: "/admin",
    meta: {
      title: "Admin",
      requiresAuth: true,
      requiresGroups: [
        constants.data().USER_GROUPS.ADMIN,
        constants.data().USER_GROUPS.SUPER_ADMIN,
      ],
    },
    redirect: { name: "settings" },
    component: () =>
      import(/* webpackChunkName: 'admin' */ "../views/admin/_Admin.vue"),
    children: [
      {
        // Admin > Settings page
        meta: {
          title: "Settings",
        },
        path: "settings",
        name: "admin-settings",
        component: () =>
          import(
            /* webpackChunkName: 'admin-settings' */ "../views/admin/Settings.vue"
          ),
      },
      {
        // Admin > Users page
        path: "users",
        name: "admin-users",
        meta: { title: "Users" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-users' */ "../views/admin/users/Users.vue"
          ),
      },
      {
        // Admin > Users edit/add page
        path: "users/:id?",
        name: "admin-users-edit",
        props: true,
        meta: { title: "Users" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-users-edit' */ "../views/admin/users/AddEditUser.vue"
          ),
      },
      {
        // Admin > Departments page
        path: "departments",
        name: "admin-departments",
        meta: { title: "Departments" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-departments' */ "../views/admin/departments/Departments.vue"
          ),
      },
      {
        // Admin > Departments edit/add page
        path: "departments/:departmentId?/:termId?",
        name: "admin-departments-edit",
        props: true,
        meta: { title: "Departments" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-department-edit' */ "../views/admin/departments/AddEditDepartment.vue"
          ),
      },
      {
        // Admin > Locked Dates page
        path: "locked-dates",
        name: "admin-locked-dates",
        meta: { title: "Locked Dates" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-locked-dates' */ "../views/admin/LockedDates.vue"
          ),
      },
      {
        // Admin > Billing page
        path: "billing",
        name: "admin-billing",
        meta: { title: "Billing" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-billing' */ "../views/admin/billing/Billing.vue"
          ),
      },
      {
        // Admin > Billing card
        path: "billing/card",
        name: "admin-billing-card",
        meta: { title: "Card" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-billing-card' */ "../views/admin/billing/Card.vue"
          ),
      },
      {
        // Admin > Integrations page
        path: "integrations/:newIntegrationType?",
        name: "admin-integrations",
        props: true,
        meta: { title: "Integrations" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-integrations' */ "../views/admin/Integrations.vue"
          ),
      },
      {
        // Admin > LeaveTypes page
        path: "leave-types",
        name: "admin-leave-types",
        meta: { title: "Leave Types" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-leave-types' */ "../views/admin/leaveTypes/LeaveTypes.vue"
          ),
      },
      {
        // Admin > LeaveTypes edit page
        path: "leave-types/:leaveTypeId?",
        name: "admin-leave-types-edit",
        props: true,
        meta: { title: "Leave Types" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-leave-types-edit' */ "../views/admin/leaveTypes/AddEditLeaveTypes.vue"
          ),
      },
      {
        // Admin > Offices page
        path: "offices",
        name: "admin-offices",
        meta: { title: "Offices" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-offices' */ "../views/admin/offices/Offices.vue"
          ),
      },
      {
        // Admin > Offices edit/add page
        path: "offices/:officeId?/:termId?",
        name: "admin-offices-edit",
        props: true,
        meta: { title: "Offices" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-offices-edit' */ "../views/admin/offices/AddEditOffice.vue"
          ),
      },
      {
        // Admin > PublicHolidays page
        path: "public-holidays",
        name: "admin-public-holidays",
        meta: { title: "Public Holidays" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-public-holidays' */ "../views/admin/publicHolidays/PublicHolidays.vue"
          ),
      },
      {
        // Admin > PublicHolidays edit page
        path: "public-holidays/:id",
        name: "admin-public-holidays-edit",
        meta: { title: "Public Holidays" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-public-holidays-edit' */ "../views/admin/publicHolidays/EditPublicHolidays.vue"
          ),
      },
      {
        // Admin > Roles page
        path: "roles",
        name: "admin-roles",
        meta: { title: "Roles" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-roles' */ "../views/admin/roles/Roles.vue"
          ),
      },
      {
        // Admin > Roles edit/add page
        path: "roles/:roleId?/:termId?",
        name: "admin-roles-edit",
        props: true,
        meta: { title: "Roles" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-roles-edit' */ "../views/admin/roles/AddEditRole.vue"
          ),
      },
      {
        // Admin > Export page
        path: "export",
        name: "admin-export",
        meta: { title: "Export Data" },
        component: () =>
          import(/* webpackChunkName: 'admin-export' */ "../views/admin/Export.vue"),
      },
      {
        // Admin > Import page
        path: "import",
        name: "admin-import",
        meta: { title: "Import data" },
        component: () =>
          import(/* webpackChunkName: 'admin-import' */ "../views/admin/Import.vue"),
      },
      {
        // Admin > Import page > Leave
        path: "import/leave",
        name: "admin-import-leave",
        meta: { title: "Import Leave" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-import-leave' */ "../views/admin/imports/Leave.vue"
          ),
      },
      {
        // Admin > Import page > Users
        path: "import/users",
        name: "admin-import-users",
        meta: { title: "Import Users" },
        component: () =>
          import(
            /* webpackChunkName: 'admin-import-users' */ "../views/admin/imports/Users.vue"
          ),
      },
      {
        // Admin > SSO
        path: "manage-sso",
        name: "admin-manage-sso",
        meta: { title: "Manage SSO" },
        component: () =>
            import(
                /* webpackChunkName: 'admin-manage-sso' */ "../views/admin/ManageSso.vue"
                ),
      },
    ],
  },
  {
    // super admin
    path: "/super-admin",
    meta: {
      title: "Super Admin",
      requiresAuth: true,
      requiresGroups: [constants.data().USER_GROUPS.SUPER_ADMIN],
    },
    redirect: { name: "wallchart" },
    component: () =>
      import(
        /* webpackChunkName: 'super-admin' */ "../views/superadmin/_Base.vue"
      ),
    children: [
      {
        // Superadmin > Customers page
        path: "customers",
        name: "super-admin-customers",
        meta: { title: "Customers - Super Admin" },
        component: () =>
          import(
            /* webpackChunkName: 'super-admin-customers' */ "../views/superadmin/customers/Customers.vue"
          ),
      },
      {
        // Superadmin > Customers edit/add page
        path: "customers/:customerId",
        name: "super-admin-customers-edit",
        props: true,
        meta: { title: "Customers - Super Admin" },
        component: () =>
          import(
            /* webpackChunkName: 'super-admin-customers-edit' */ "../views/superadmin/customers/EditCustomer.vue"
          ),
      },
      {
        // Superadmin > Customer's users page
        path: "customers/:customerId/users",
        name: "super-admin-customers-users",
        props: true,
        meta: { title: "Customer's users - Super Admin" },
        component: () =>
            import(
                /* webpackChunkName: 'super-admin-customers' */ "../views/superadmin/customers/Users.vue"
                ),
      },
      {
        // Superadmin > Billing page
        path: "invoices",
        name: "super-admin-invoices",
        meta: { title: "Invoices - Super Admin" },
        component: () =>
          import(
            /* webpackChunkName: 'super-admin-invoices' */ "../views/superadmin/Invoices.vue"
          ),
      },
      {
        // Superadmin > Reporting page
        path: "reporting",
        name: "super-admin-reporting",
        meta: { title: "Reporting - Super Admin" },
        component: () =>
          import(
            /* webpackChunkName: 'super-admin-reporting' */ "../views/superadmin/Reporting.vue"
          ),
      },
      {
        // Superadmin > Customer's reference
        path: "report-customer-referrer",
        name: "super-admin-report-customer-referrer",
        meta: { title: "Customer Referrer Report - Super Admin" },
        component: () =>
            import(
                /* webpackChunkName: 'super-admin-report-customer-referrer' */ "../views/superadmin/reports/CustomerReferrer.vue"
                ),
      },
      {
        // Superadmin > Email log
        path: "email-log",
        name: "super-admin-email-log",
        meta: { title: "Superadmin - Email Log - Super Admin" },
        component: () =>
          import(
            /* webpackChunkName: 'super-admin-email-log' */ "../views/superadmin/EmailLog.vue"
          ),
      },
      {
        // Superadmin > Manage public holidays
        path: "public-holidays",
        name: "super-admin-public-holidays",
        meta: { title: "Public Holidays Management - Super Admin" },
        component: () =>
          import(
            /* webpackChunkName: 'super-admin-public-holidays' */ "../views/superadmin/publicHolidays/PublicHolidays.vue"
          ),
      },
      {
        // Superadmin > Manage public holidays - edit
        path: "public-holidays/:id",
        name: "super-admin-public-holidays-edit",
        meta: { title: "Public Holidays Edit - Super Admin" },
        component: () =>
          import(
            /* webpackChunkName: 'super-admin-public-holidays-edit' */ "../views/superadmin/publicHolidays/EditPublicHolidays.vue"
          ),
      },
    ],
  },
  {
    // Error page
    path: "/error/:type",
    name: "error",
    props: true,
    meta: { title: "Error", requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: 'error' */ "../views/common/Error.vue"),
  },
  {
    path: "*",
    redirect: {
      name: "error",
      params: { msg: "error", type: 404 },
    }, // Redirect everything else to error page e.g. 404
  },
];
