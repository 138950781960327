// Export main item
import appConfig from "../config";
import moment from "moment";
import constants from "../mixins/const.js";

export let helpers = {
  config: appConfig,
  pendoInstall(pendoId, userId, customerId) {
    if (document.getElementById('pendoLoader') === null) {
      let externalScript = document.createElement('script');
      externalScript.setAttribute('id', 'pendoLoader')
      externalScript.appendChild(document.createTextNode(`
        (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
        })('${pendoId}');
        pendo.initialize({
          visitor: {
            id: "${this.config.environment}-${userId}"
          },
          account: {
            id: "${this.config.environment}-${customerId}"
          }
        });
      `));
      document.head.appendChild(externalScript);
    }
  },
  pendoDisplayGuide(pendoGuideId) {
    if (document.getElementById('pendoGuide') === null) {
      let externalScript = document.createElement('script');
      externalScript.setAttribute('id', 'pendoGuide')
      externalScript.appendChild(document.createTextNode(`
          pendo.showGuideById('${pendoGuideId}');
      `));
      document.body.appendChild(externalScript);
    }
  },
  isLoggedIn() {
    return !!localStorage.getItem("authToken");
  },
  authLogin(data) {
    localStorage.setItem("authToken", data.token);
    localStorage.setItem("authExpiry", data.expiry);
  },
  authLogout() {
    localStorage.removeItem("authToken");
    localStorage.removeItem("authExpiry");
  },
  isTokenExpiringSoon() {
    const refreshTokenTime =
      (appConfig.refreshTokenMin ? appConfig.refreshTokenMin : 30) * 60 * 1000;
    return new Date() > this.getExpiryTimeFromStoredToken() - refreshTokenTime;
  },
  getExpiryTimeFromStoredToken() {
    try {
      if (!localStorage.getItem("authToken")) {
        return 0;
      }

      return localStorage.getItem("authExpiry") * 1000;
    } catch (e) {
      return 0;
    }
  },
  isAdmin(user) {
    return this.isCurrentUserInGroup(user, [
      constants.data().USER_GROUPS.ADMIN,
    ]);
  },
  isApprover(user) {
    return user.groups.find((perm) =>
        perm.safeName.toLowerCase().includes("approver")
    );
  },
  isApproverFor(approver, approvee) {
    if (approver === null || approvee === null) {
      return false;
    }
    if (
      approver.groups.find((perm) =>
        perm.safeName.toLowerCase().includes("admin")
      )
    ) {
      return !(
        approver.id === approvee.id &&
        approvee.settings &&
        approvee.settings.approversEnabled
      );
    }
    if (
      approver.groups.find((perm) =>
        perm.safeName.toLowerCase().includes("approver")
      ) &&
      (approver.approvingDepartments || approver.approvingUsers)
    ) {
      let isApprover = false;
      if (approvee.settings && approvee.settings.approversEnabled) {
        approver.approvingUsers.forEach((user) => {
          if (approvee.id === user.id) {
            isApprover = true;
          }
        });
      } else {
        approver.approvingDepartments.forEach((term) => {
          approvee.userTerms.forEach((userTerm) => {
            if (userTerm.id === term.id) {
              isApprover = true;
            }
          });
        });
      }
      return isApprover;
    }

    return false;
  },
  isCurrentUserInGroup(user, groups, type = "OR") {
    if (!user || !user.groups) {
      return false;
    }
    let found = 0;
    groups.forEach((searchedGroup) => {
      found += user.groups.find((perm) =>
        perm.safeName.toLowerCase().includes(searchedGroup)
      )
        ? 1
        : 0;
    });

    if (type === "OR") {
      return found > 0;
    } else {
      return found === groups.length;
    }
  },
  showBradfordFactor(userAccessing) {
    return (
      userAccessing.customer.extra?.bradfordFactorEnabled &&
      (this.isCurrentUserInGroup(userAccessing, [
        constants.data().USER_GROUPS.SUPER_ADMIN,
        constants.data().USER_GROUPS.ADMIN,
      ]) ||
        (this.isCurrentUserInGroup(userAccessing, [
          constants.data().USER_GROUPS.APPROVER,
        ]) &&
          userAccessing.customer.settings.bradfordFactorVisibleApprover))
    );
  },
  getCurrentWorkingHours(workingHours) {
    const index = workingHours.findIndex((item, index) => {
      return (
        (item.startDate === null &&
          (workingHours[index - 1] === undefined ||
            moment(workingHours[index - 1].startDate).isAfter(moment()))) ||
        (item.startDate !== null &&
          moment().isAfter(item.startDate) &&
          (workingHours[index - 1] === undefined ||
            moment().isBefore(workingHours[index - 1].startDate)))
      );
    });

    return workingHours[index]
      ? workingHours[index].options
      : workingHours[0].options;
  },
  countWorkingHours(item) {
    if (!item.settings.workHoursEnabled || !item.workingHours) {
      return "Default";
    }
    const workingHours = this.getCurrentWorkingHours(item.workingHours);
    let minutes = 0;
    Object.keys(workingHours).forEach((day) => {
      Object.keys(workingHours[day]).forEach((dayPart) => {
        let startTime = moment(
            moment().format("YYYY-MM-DD") +
              " " +
              workingHours[day][dayPart]["start"],
            "YYYY-MM-DD HH:mm"
          ),
          endTime = moment(
            moment().format("YYYY-MM-DD") +
              " " +
              workingHours[day][dayPart]["end"],
            "YYYY-MM-DD HH:mm"
          );
        minutes += endTime.diff(startTime, "minutes");
      });
    });

    return Math.round((minutes / 60) * 100) / 100;
  },
  getLeaveTypeName(leaveType) {
    let leaveTypeName = leaveType.name;
    switch (leaveType.paid) {
      case "STATUTORY":
        leaveTypeName += ` (Statutory)`;
        break;
      case "PAID":
        leaveTypeName += ` (Paid)`;
        break;
      case "UNPAID":
        leaveTypeName += ` (Unpaid)`;
        break;
      default:
    }

    return leaveTypeName;
  },
  getLeaveTypePaid(leaveType) {
    switch (leaveType.paid) {
      case "STATUTORY":
        return `Statutory`;
      case "PAID":
        return `Paid`;
      case "UNPAID":
        return `Unpaid`;
      default:
        return "";
    }
  },
  getBookingTimeInUserFormat(type, time) {
    switch (type) {
      case "END":
        return time === constants.data().DAYS_HOURS.END_OF_DAY.INTEGER
          ? constants.data().DAYS_HOURS.END_OF_DAY.NAME
          : constants.data().DAYS_HOURS.END_LUNCH.NAME;
      case "START":
        return time === constants.data().DAYS_HOURS.START_MORNING.INTEGER
          ? constants.data().DAYS_HOURS.START_MORNING.NAME
          : constants.data().DAYS_HOURS.START_AFTERNOON.NAME;
      default:
        return time;
    }
  },
  getUserTermByTaxonomy(user, type) {
    if (!user.userTerms) {
      return null;
    }
    let termName = null;
    user.userTerms.forEach((item) => {
      if (item.taxonomy && item.taxonomy.safeName === type) {
        termName = item.name;
      }
    });

    return termName;
  },
  getTimeInUserTimezone(user, time) {
    let timezone = null;
    if (
      user.customer &&
      user.customer.settings &&
      user.customer.settings.timezone
    ) {
      timezone = user.customer.settings.timezone;
    }
    if (user.settings && user.settings.timezone) {
      timezone = user.settings.timezone;
    }
    return timezone ? moment(time).tz(timezone) : moment(time);
  },
  getBradfordFactorColour(points) {
    if (points <= 200) {
      return "text-success";
    } else if (points <= 500) {
      return "text-warning";
    } else {
      return "text-danger";
    }
  },
  generateRandomString(length) {
    let result = '', counter = 0;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
      counter += 1;
    }
    return result;
  }
};
