<template>
  <div class="d-inline-block">
    <small title="Click to view the help guide" v-b-tooltip>
      <font-awesome-icon
        @click="openGuide"
        id="helpGuide"
        class="cursor-pointer ml-1 text-secondary"
        icon="question-circle"
      />
    </small>
  </div>
</template>

<script>
export default {
  name: "OpenGuide",
  props: {
    article: String,
  },
  methods: {
    openGuide() {
      try {
        // eslint-disable-next-line no-undef
        Beacon("article", this.article, {
          type: "sidebar",
        });
      } catch (error) {
        this.error(error);
      }
    },
  },
};
</script>
