<template>
  <div>
    <!-- Snotify import -->
    <vue-snotify/>
    <b-container fluid class="p-0">
      <Loading v-if="$store.state.loading" class="maxHeight"/>
      <router-view/>
    </b-container>
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";

export default {
  name: "App",
  components: {
    Loading,
  },
  mounted() {
    this.$loadScript('https://js.stripe.com/v3/');
    if (process.env.VUE_APP_GOOGLE_ANALYTICS && process.env.VUE_APP_GOOGLE_ANALYTICS.length) {
      this.$loadScript(`https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GOOGLE_ANALYTICS}`)
          .then(() => {
            const googleScript = document.createElement("script");
            googleScript.appendChild(document.createTextNode(`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag("config", "${process.env.VUE_APP_GOOGLE_ANALYTICS}");
            `));
            document.body.appendChild(googleScript);
          })
          .catch(() => {});
    }
    if (process.env.VUE_APP_BEACON_ID && process.env.VUE_APP_BEACON_ID.length) {
      const beaconScript = document.createElement("script");
      beaconScript.appendChild(document.createTextNode(`
      !(function(e, t, n) {
        function a() {
          var e = t.getElementsByTagName("script")[0],
              n = t.createElement("script");
          (n.type = "text/javascript"),
              (n.async = !0),
              (n.src = "https://beacon-v2.helpscout.net"),
              e.parentNode.insertBefore(n, e);
        }
        if (
            ((e.Beacon = n = function(t, n, a) {
              e.Beacon.readyQueue.push({ method: t, options: n, data: a });
            }),
                (n.readyQueue = []),
            "complete" === t.readyState)
        )
          return a();
        e.attachEvent
            ? e.attachEvent("onload", a)
            : e.addEventListener("load", a, !1);
      })(window, document, window.Beacon || function() {});
      window.Beacon("init", "${process.env.VUE_APP_BEACON_ID}");
      `));
      document.head.appendChild(beaconScript);
    }
  },
};
</script>

<style lang="scss">
@import "assets/css/global.scss";
@import "assets/css/variables.scss";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
</style>
