<template>
  <div>
    <div>
      <b-row class="mb-1">
        <b-col v-if="label"
          ><label class="font-weight-bold">{{ label }}</label>
        </b-col>
        <b-col cols="12">
          <div v-if="editing">
            <b-row>
              <b-col md="10">
                <b-form-input
                  v-if="type === 'input'"
                  :name="fieldName"
                  v-model="localValue"
                  :placeholder="fieldPlaceholder"
                />
                <datepicker
                  v-else-if="type === 'datepicker'"
                  monday-first
                  v-model="localValue"
                  :highlighted="{ dates: [new Date()] }"
                />
                <b-textarea
                  v-else-if="type === 'textarea'"
                  :name="fieldName"
                  v-model="localValue"
                />
                <b-form-radio-group
                  v-else-if="type === 'radio'"
                  :name="fieldName"
                  :options="fieldOptions"
                  v-model="localValue"
                />
              </b-col>
              <b-col md="2">
                <span
                  @click="saveEdit"
                  class="text-primary cursor-pointer mt-2"
                  title="Save changes"
                  v-b-tooltip
                >
                  <font-awesome-icon icon="save" class="mt-2" />
                </span>
                <span
                  @click="cancelEdit"
                  class="text-danger cursor-pointer"
                  title="Cancel edit"
                  v-b-tooltip
                >
                  <font-awesome-icon icon="ban" class="mt-2 ml-2"
                /></span>
              </b-col>
            </b-row>
          </div>
          <div v-else class="mt-0">
            <span v-if="type === 'radio'">
              {{ localValue ? "true" : "false" }}
            </span>
            <span v-else>{{ localValue ? localValue : "N/A" }}</span>
            <span
              v-if="fieldExtras"
              class="ml-5 mr-2 font-italic"
              >{{ fieldExtras }}
            </span>
            <span
              @click="startEdit"
              class="text-primary cursor-pointer"
              title="Start editing"
              v-b-tooltip
            >
              <font-awesome-icon :icon="['far', 'edit']" class="ml-2" />
            </span>
            <span
              v-if="allowDeletion"
              @click="$emit('inlineEdit:delete', fieldIndex)"
              class="text-primary cursor-pointer"
              title="Delete"
              v-b-tooltip
            >
              <font-awesome-icon :icon="['fas', 'power-off']" class="ml-2" />
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "InlineEdit",
  props: {
    type: {
      type: String,
      default: "input",
      required: true,
    },
    fieldIndex: {
      type: Number,
      default: null,
    },
    fieldName: {
      type: String,
      required: true,
    },
    fieldValue: {
      type: [ String, Boolean ],
    },
    fieldExtras: {
      type: String,
    },
    fieldOptions: {
      type: Array
    },
    fieldPlaceholder: {
      type: String,
      default: "Enter value"
    },
    label: {
      type: String,
    },
    patchUrl: {
      type: String,
    },
    allowDeletion: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editing: false,
      localValue: null,
    };
  },
  mounted() {
    const root = this;
    root.localValue = root.fieldValue;
    if (root.showEdit) {
      root.editing = true;
    }
  },
  methods: {
    startEdit() {
      this.editing = true;
    },
    cancelEdit() {
      this.localValue = this.fieldValue;
      this.editing = false;
      if (this.showEdit && (!this.localValue || this.localValue.length === 0)) {
        this.$emit("inlineEdit:delete", this.fieldIndex);
      }
    },
    saveEdit() {
      const root = this;
      let finalValue;
      switch (root.type) {
        case "input":
        case "datepicker":
          finalValue =
            root.localValue && root.localValue.length ? root.localValue : null;
          break;
        case "radio":
          finalValue = !!root.localValue;
          break;
        default:
          finalValue = null;
      }
      if (root.patchUrl) {
        root.$HTTP
          .patch(
            root.patchUrl,
            root.convertDotsToObject(
              root.fieldName,
              finalValue
            )
          )
          .then(() => {
            root.$snotify.success(`Field ${root.label} was updated`);
            root.editing = false;
          })
          .catch((errorResponse) => {
            let message = `Field ${root.label} was NOT updated`;
            if (errorResponse.response.data.errorCode === 6) {
              message +=
                " " + errorResponse.response.data.errorDetails.join(" ");
            }
            root.$snotify.error(message, {
              timeout: 5000,
            });
          });
      } else {
        root.$emit("inlineEdit:save", root.fieldIndex, finalValue);
        root.editing = false;
      }
    },
    convertDotsToObject(str, val = null) {
      return str.split(".").reduceRight((acc, currentValue) => {
        return { [currentValue]: acc };
      }, val);
    },
  },
};
</script>
