export default {
  data() {
    return {
      timeoutHandler: null,
    };
  },
  methods: {
    debouncing(fn) {
      const args = arguments,
        t = this;
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = setTimeout(() => {
        fn.apply(t, args);
      }, t.$helpers.config.debounceTimeout || 500);
    },
  },
};
