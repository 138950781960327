export default {
  methods: {
    logout() {
      const root = this;
      if (localStorage.getItem("authToken") === null) {
        root.$router.go();
      }
      root.$HTTP
        .delete("/auth")
        .catch(() => {})
        .finally(() => {
          localStorage.removeItem("authToken");
          localStorage.removeItem("authExpiry");
          root.$router.push(
              { name: "login"},
            () => {},
            () => {}
          );
        });
    },
    getRandomColour(int) {
      const colours = [
        "#6772e5",
        "#0069ff",
        "#3ecf8e",
        "#ff3b00",
        "#ff9d00",
        "#e681ff",
        "#803dff",
        "#ff4c6c",
        "#80d34a",
        "#ff8000",
        "#ffc800",
        "#00c6ff",
        "#01d0c0",
      ];
      if (typeof colours[int] !== "undefined") {
        return colours[int];
      } else {
        return colours[Math.floor(Math.random() * 10 + 1)];
      }
    },
    controlTask(taskId, message, options = {}) {
      const root = this;
      let downloadCheck = setInterval(() => {
        root.$HTTP
          .get(`/tasks/${taskId}`)
          .then( (response) => {
            switch(response.data.status) {
              case 'FAILED':
                clearInterval(downloadCheck);
                downloadCheck = null;
                root.$snotify.error(response.data.output.errors.join("<br /"), {
                  timeout: 5000,
                });
                options.callback && options.callback();
                break;
              case 'EXECUTED':
                clearInterval(downloadCheck);
                downloadCheck = null;
                root.$snotify.success(message);
                options.callback && options.callback();
                window.location.href = response.data.output.url;
                break;
              default:
            }
          })
          .catch(() => {
            root.$snotify.error("Task execution failed. Please try again");
          });
      }, options.timeout ?? 2000);
    },
    convertFilters(filterFunction) {
      const terms = [];
      if (filterFunction().department) {
        terms.push(filterFunction().department);
      }
      if (filterFunction().role) {
        terms.push(filterFunction().role);
      }
      if (filterFunction().office) {
        terms.push(filterFunction().office);
      }
      if (terms.length) {
        filterFunction().terms = terms;
      }
      return filterFunction;
    },
  },
};
