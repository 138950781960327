// Imports
import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import routingSecurity from "./routerSecurity";
import store from "../store";

// Use the router
Vue.use(Router);

// Router definition
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

// Set the security
routingSecurity(router);

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    store.commit("updateLoading", true);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  store.commit("updateLoading", false);
});

// Export
export default router;
