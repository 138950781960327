// Imports
import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

// Use the Vuex
Vue.use(Vuex);

// Set main state
const state = {
  user: {},
  pendingLeaves: null,
  loading: true,
  runningTasks: [],
};

// Setup store
const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});

// For hot-reloading functionality when doing development with webpack
if (module.hot) {
  module.hot.accept(["./actions", "./mutations"], () => {
    store.hotUpdate({
      actions: require("./actions"),
      mutations: require("./mutations"),
    });
  });
}

export default store;
