<template>
  <div>
    <b-modal
      id="timeoutModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      size="md"
      header-bg-variant="warning"
      header-text-variant="light"
      hide-footer
      title="Session Inactivity Warning"
    >
      <p class="text-center my-5">
        You will be logged out within the next {{ minutes }} minutes. If you
        wish to stay logged in, please click the "Yes" button below
      </p>
      <b-row>
        <b-col class="mt-3">
          <b-button
            id="button-timeout-yes"
            class="float-right"
            variant="warning"
            @click="refreshLogin()"
            >Yes</b-button
          >
          <b-button
            id="button-timeout-no"
            @click="logout()"
            variant="outline-secondary"
            >No</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "TimeoutWindow",
  props: {},
  data() {
    return {
      minutes: this.$helpers.config.timedOutWindowDelayMin
        ? this.$helpers.config.timedOutWindowDelayMin
        : 3,
      showLogoutWindowTimeout: null,
      logoutTimeout: null,
    };
  },
  mounted() {
    if (this.$helpers.isTokenExpiringSoon()) {
      this.refreshToken();
    }
    this.startTimeoutCounting();
  },
  methods: {
    startTimeoutCounting() {
      const root = this;
      clearTimeout(root.showLogoutWindowTimeout);
      clearTimeout(root.logoutTimeout);
      root.showLogoutWindowTimeout = setTimeout(() => {
        root.$bvModal.show("timeoutModal");
        const randomDelay = Math.floor(Math.random() * 10) + root.minutes * 60;
        root.logoutTimeout = setTimeout(() => {
          //check if token wasn't refreshed in different browser window
          if (!root.$helpers.isTokenExpiringSoon()) {
            root.$bvModal.hide("timeoutModal");
            return;
          }

          root.logout();
        }, randomDelay * 1000);
      }, root.calculateTimeoutToShowModal());
    },
    refreshLogin() {
      const root = this;
      clearTimeout(root.logoutTimeout);
      root.logoutTimeout = null;
      root.$bvModal.hide("timeoutModal");
      root.refreshToken();
    },
    refreshToken() {
      const root = this;
      root.$HTTP
        .post("/auth/refresh")
        .then((response) => {
          root.$helpers.authLogin(response.data);
          root.startTimeoutCounting();
        })
        .catch(() => {
          root.logout();
        });
    },
    calculateTimeoutToShowModal() {
      return (
        this.$moment(this.$helpers.getExpiryTimeFromStoredToken())
          .subtract(this.minutes, "minutes")
          .valueOf() -
        this.$moment().valueOf() -
        30000
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/variables.scss";
</style>
