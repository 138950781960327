// Imports
import axios from "axios";
import appConfig from "../config";
import router from "../router/router";
import Qs from "qs";
import { helpers } from "./helpers.js";

// Export main item
export let HTTP = axios.create({
  baseURL: appConfig.apiUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
  paramsSerializer: {
    serialize: (params) => {
      return Qs.stringify(params, {encodeValuesOnly: true});
    }
  },
});

// Middleware (interceptor) for injection of access token
HTTP.interceptors.request.use(
  (config) => {
    // We don't want headers passed to DO, as it can kill the uploads
    if (config.url.includes("digitaloceanspaces.com")) {
      delete axios.defaults.headers;
    } else {
      const token = localStorage.getItem("authToken");
      if (token) { config.headers["Access-Token"] = token; }
    }
    return config;
  },
  (errorResponse) => {
    return Promise.reject(errorResponse);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    if (response.config.url === "/users/me") {
      if (parseFloat(response.headers.version) !== parseFloat(require("../../package.json").version)
          &&  (!localStorage.getItem('lastVersionReload')
              || Number(localStorage.getItem('lastVersionReload')) + 10000 < Date.now())
      ) {
        localStorage.setItem('lastVersionReload', Date.now().toString());
        location.reload();
      }
    }

    return response;
  },
  (rejectedPromise) => {
    //catch all 401 errors and remove token and reload page (then they will go to login with proper redirect address)
    if (rejectedPromise.response?.status === 401) {
      helpers.authLogout();
      router.push({
        name: "login",
        hash: "#unauth",
      }, () => {}, () => {});
      return Promise.reject(rejectedPromise);
    }

    return Promise.reject(rejectedPromise);
  }
);
