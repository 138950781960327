import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner,
  faFileAlt,
  faFileContract,
  faCreditCard,
  faTasks,
  faAlignLeft,
  faSlidersH,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faCogs,
  faUserTie,
  faBuilding,
  faUserMd,
  faPlane,
  faUmbrellaBeach,
  faMoneyCheck,
  faUser,
  faUserGroup,
  faSignOutAlt,
  faUserCircle,
  faPowerOff,
  faArrowLeft,
  faArrowRight,
  faRss,
  faQuestionCircle,
  faCalendarAlt,
  faBan,
  faFileDownload,
  faFileUpload,
  faChartArea,
  faCheckCircle,
  faSave,
  faInfoCircle,
  faLock,
  faUnlock,
  faSuitcaseRolling,
  faBirthdayCake,
  faPlus,
  faEnvelope,
  faPhone,
  faStarHalfAlt,
  faClock,
  faList,
  faChevronDown,
  faEnvelopeOpenText,
  faPrint,
  faIdCard,
  faTimes,
  faPaperPlane,
  faDesktop,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAddressCard,
  faClipboard,
  faEdit,
  faUser as farUser,
  faBuilding as farBuilding,
  faCreditCard as farCreditCard,
  faCalendarCheck as farCalendarCheck,
  faCalendarTimes,
  faTrashAlt,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSlack, faMicrosoft } from "@fortawesome/free-brands-svg-icons";

library.add(
  faSpinner,
  faFileAlt,
  faFileContract,
  faCreditCard,
  faClipboard,
  faTasks,
  faAlignLeft,
  faSlidersH,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faCogs,
  faUserTie,
  faBuilding,
  faUserMd,
  faPlane,
  faUmbrellaBeach,
  faMoneyCheck,
  faUser,
  faUserGroup,
  faSignOutAlt,
  faUserCircle,
  faPowerOff,
  faSlack,
  faAddressCard,
  faEdit,
  faArrowLeft,
  faArrowRight,
  faRss,
  faQuestionCircle,
  faCalendarAlt,
  farUser,
  farBuilding,
  farCreditCard,
  faBan,
  faFileDownload,
  faFileUpload,
  farCalendarCheck,
  faCalendarTimes,
  faChartArea,
  faTrashAlt,
  faCheckCircle,
  faSave,
  faInfoCircle,
  faLock,
  faUnlock,
  faSuitcaseRolling,
  faBirthdayCake,
  faPlus,
  faEnvelopeOpen,
  faEnvelope,
  faPhone,
  faStarHalfAlt,
  faClock,
  faList,
  faChevronDown,
  faList,
  faEnvelopeOpenText,
  faPrint,
  faMicrosoft,
  faIdCard,
  faTimes,
  faPaperPlane,
  faDesktop,
  faBroom
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
