// Main mutations
export const mutations = {
  // Update user
  updateUser(state, user) {
    state.user = user;
  },
  updateLoading(state, loading) {
    state.loading = loading;
  },
  updatePendingLeaves(state, count) {
    state.pendingLeaves = count;
  },
  createRunningTask(state, data) {
    state.runningTasks.push({
      taskId: data.taskId,
      interval: data.interval,
      counter: 1,
    });
  },
  removeRunningTask(state, taskId) {
    const index = state.runningTasks.findIndex(item => item.taskId === taskId);
    if (index === -1) {
      return;
    }
    clearInterval(state.runningTasks[index].interval);
    state.runningTasks.splice(index, 1);
  },
  increaseCounterRunningTask(state, taskId) {
    const index = state.runningTasks.findIndex(item => item.taskId === taskId);
    if (index === -1) {
      return;
    }

    state.runningTasks[index].counter++;
  },
};
