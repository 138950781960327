import { requiredIf, between, integer, minValue } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      get DAYS_HOURS() {
        return {
          START_MORNING: {
            INTEGER: 1,
            NAME: "Morning",
          },
          START_AFTERNOON: {
            INTEGER: 5,
            NAME: "Afternoon",
          },
          END_LUNCH: {
            INTEGER: 4,
            NAME: "Lunch Time",
          },
          END_OF_DAY: {
            INTEGER: 8,
            NAME: "End of Day",
          },
        };
      },
      DATE_USER_FORMAT: "Do MMM YYYY",
      DATE_USER_HOUR_FORMAT: "Do MMM YYYY, HH:mm:ss",
      DATE_API_FORMAT: "YYYY-MM-DD",
      DATE_FULL_YEAR_FORMAT: "YYYY",
      DATE_CALENDAR_MONTHS_NAME_FORMAT: "MMMM YYYY",
      DATE_BILLING_FORMAT: "MMMM-YY",
      TASKS: {
        STATUS_PENDING: "PENDING",
        STATUS_PROCESSING: "PROCESSING",
        STATUS_EXECUTED: "EXECUTED",
        STATUS_FAILED: "FAILED",
      },
      LEAVE_TYPE_COLOUR: "#bbbbbb",
      FILE_SIZE_IMPORT_LEAVE: 20 * 1024 * 1024,
      FILE_TYPE_IMPORT_LEAVE: "leaveImport",
      FILE_SIZE_IMPORT_USER: 20 * 1024 * 1024,
      FILE_TYPE_IMPORT_USER: "userImport",
      FILE_SIZE_AVATAR_UPLOAD: 500 * 1024,
      USER_GROUPS: {
        APPROVER: "approver",
        ADMIN: "admin",
        SUPER_ADMIN: "superadmin",
      },
      APPROVER_LEVEL_ONE: 1,
      APPROVER_LEVEL_TWO: 2,
      APPROVER_LEVEL_THREE: 3,
      TAXONOMY_DEPARTMENTS: "departments",
      TAXONOMY_OFFICES: "offices",
      TAXONOMY_ROLES: "roles",
      USER_NOTIFICATIONS: [
        {
          text: "All Bookings Requested",
          value: "allBookingsRequested",
        },
        {
          text: "All Bookings Approved",
          value: "allBookingsApproved",
        },
        {
          text: "All Bookings Cancelled",
          value: "allBookingsCancelled",
        },
      ],
      USERS: {
        STATUS_ACTIVE: "ACTIVE",
        STATUS_INACTIVE: "INACTIVE",
      },
      TEXT_SHORTENER_LENGTH: 30,
      NUDGES: [
        {
          safeName: "unusedAllowance",
          name: "Unused Allowance Nudge",
          params: [
            {
              safeName: "allowanceThreshold",
              description: "Send nudges when users have more than the following unused allowance left",
              placeholder: "Enter number bigger than zero",
              error: "Value has to be an integer bigger than zero",
              validations: {
                required: requiredIf(function() {
                  return this.nudges['unusedAllowance'] && this.nudges['unusedAllowance'].enabled
                }),
                minValue: minValue(1),
                integer,
              },
            },
            {
              safeName: "nudgeMonthBeforeYearEnd",
              description: "Send nudges this many months before the company leave year end date",
              placeholder: "Enter number between 1 and 11",
              error: "Value has to be a number between 1 and 11",
              validations: {
                required: requiredIf(function() {
                  return this.nudges['unusedAllowance'] && this.nudges['unusedAllowance'].enabled
                }),
                between: between(1, 11),
                integer,
              },
            },
          ],
        },
      ],
    };
  },
};
